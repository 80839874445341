/* Card.css */
.CartBtn {
    width: 100%;
    height: auto;
    border-radius: 12px;
    border: none;
    background-color: #4e2a1b;
    display: flex;
    margin-top: 10px;
    bottom: 0%;
    padding: 7px 0px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: .5s;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.103);
    position: relative;
  }
  
  .IconContainer {
    position: absolute;
    left: -50px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
    transition-duration: .5s;
  }

  .text {
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFEEA9;
    z-index: 1;
    transition-duration: .5s;
    font-weight: 600;
  }
  
  .CartBtn:hover .IconContainer {
    transform: translateX(80px);
    border-radius: 40px;
    transition-duration: .5s;
  }
  
  .CartBtn:hover .text {
    transform: translate(10px,0px);
    transition-duration: .5s;
  }
  
  .CartBtn:active {
    transform: scale(0.95);
    transition-duration: .5s;
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden;
  }
  
  .main-image {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 5;
  }
  
  .hover-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 10;
  }
  
  .image-container:hover .main-image {
    opacity: 0;
  }
  
  .image-container:hover .hover-image {
    opacity: 1;
  }

  @media screen and (max-width: 575px) {
    .CartBtn {
      height: 30px;
      border-radius: 5px;
    }
    
    .CartBtn:hover .IconContainer {
      transform: translateX(60px);
    }
  }

  @media screen and (min-width: 330px) and (max-width: 450px) {
    .image-container {
      height: 200px;
    }
  
    .CartBtn {
      height: auto;
      padding: 3px 0px;
      border-radius: 8px;
    }
    
    .CartBtn:hover .IconContainer {
      transform: translateX(60px);
    }
  }

  @media screen and (min-width: 451px) and (max-width: 900px) {
    .image-container {
      height: 250px;
    }
  
    .CartBtn {
      height: auto;
      padding: 5px 0px;
      border-radius: 8px;
    }
    
    .CartBtn:hover .IconContainer {
      transform: translateX(60px);
    }
  }